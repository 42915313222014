

.ktb-var-hover-background {
  color: #AD172B;
}
.ktb-var-hover-gradient {

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
}
.ktb-var-series-a {
  color: #AD172B;
}
.k-button.k-state-hover,
.k-button:hover {
  color: #fff;
  border-color: #AD172B;
  background-color: #AD172B;
}
.k-button.k-state-active:hover,
.k-button.k-state-selected:hover {
  color: #fff;
  border-color: #AD172B;
  background-color: #AD172B;
}
.k-primary.k-state-hover,
.k-primary:hover {
  color: #fff;
  border-color: #AD172B;
  background-color: #AD172B;
}
.k-split-button.k-state-border-down > .k-button,
.k-split-button.k-state-border-up > .k-button {
  color: #fff;
  background-color: #AD172B;
  border-color: #AD172B;
  box-shadow: none;
}
.k-calendar td.k-state-hover .k-link {
  border-color: #AD172B;
  color: #fff;
  background-color: #AD172B;

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
}
.k-calendar-range td.k-state-hover .k-link {
  border-color: #AD172B;
  color: #fff;
  background-color: #AD172B;
}
.k-switch-on.k-state-hover .k-switch-container,
.k-switch-on:hover .k-switch-container {
  border-color: #AD172B;

  background-color: #fff;
}
.k-switch-on.k-state-hover .k-switch-handle,
.k-switch-on:hover .k-switch-handle {
  border-color: #AD172B;

  background-color: #AD172B;
}
.k-switch-off.k-state-hover .k-switch-container,
.k-switch-off:hover .k-switch-container {
  border-color: #AD172B;
  color: #787878;
  background-color: #fff;
}
.k-switch-off.k-state-hover .k-switch-handle,
.k-switch-off:hover .k-switch-handle {
  border-color: #AD172B;
  color: #787878;
  background-color: #AD172B;
}
.k-drawer-item.k-state-hover,
.k-drawer-item:hover {
  color: #fff;
  background-color: #AD172B;

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
}
.k-drawer-item.k-state-focused,
.k-drawer-item:focus {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #AD172B;
}
.k-drawer-item.k-state-focused.k-state-hover,
.k-drawer-item.k-state-focused:hover,
.k-drawer-item:focus.k-state-hover,
.k-drawer-item:focus:hover {
  color: #fff;
  background-color: #AD172B;
}
.k-timeline .k-timeline-card .k-card-body::-webkit-scrollbar-thumb:hover {
  background: #AD172B;
}
.k-breadcrumb.k-state-focused,
.k-breadcrumb:focus {
  box-shadow: inset 0 0 0 1px #AD172B;
}
.k-draghandle {
  border-color: #AD172B;
  background-color: #AD172B;
  box-shadow: 0 1px 1px 0 rgba(95, 95, 95, 0.3);
}
.k-tabstrip-scrollable .k-button.k-flat:hover {
  background: #AD172B !important;
}
.k-menu .k-menu-scroll-button:hover,
.k-menu .k-state-hover > .k-link {
  color: #fff;
  background-color: #AD172B;
  border-color: #AD172B;

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
}
.k-grouping-row .k-state-focused,
.k-state-focused {
  border-color: #AD172B;
}
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first,
.k-virtual-item.k-first {
  border-top-color: #AD172B;
}
.k-group-header + div > .k-list > .k-item.k-first:before {
  border-top-color: #AD172B;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  background: #AD172B;
  color: #fff;
}
.k-popup .k-list .k-item > .k-group {
  background: #AD172B;
  color: #fff;
  border-bottom-left-radius: 0;
}
.k-grid-header th.k-state-focused,
.k-list > .k-state-focused,
.k-listview > .k-state-focused,
.k-state-focused,
td.k-state-focused {
  box-shadow: inset 0 0 0 1px #AD172B;
}
.k-dropdown .k-state-focused,
.k-filebrowser-dropzone,
.k-list > .k-state-hover,
.k-splitbar-horizontal-hover,
.k-splitbar-vertical-hover,
.k-state-hover {
  color: #fff;
  background-color: #AD172B;
  border-color: #AD172B;
}
.k-state-focused > .k-select,
.k-state-hover > .k-select {
  border-color: #AD172B;
}
.k-draghandle:hover,
.k-listbox .k-item:hover:not(.k-state-disabled),
.k-other-month.k-state-hover .k-link,
.k-state-hover,
div.k-filebrowser-dropzone em {

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
}
.k-pager-wrap .k-link:hover {
  color: #fff;
  background-color: #AD172B;
  border-color: #AD172B;
}
.k-autocomplete.k-state-focused,
.k-autocomplete.k-state-hover,
.k-dropdown-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-picker-wrap.k-state-hover {
  color: #fff;
  background-color: #AD172B;

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
  background-position: 50% 50%;
  border-color: #dbdbdb;
}
.k-textarea:hover,
.k-textbox:hover {
  border-color: #AD172B;
}
.k-gantt .k-treelist tr:hover {
  background-color: #AD172B;
}
.k-gantt .k-treelist .k-state-selected:hover,
.k-gantt .k-treelist .k-state-selected:hover td {
  background-color: #AD172B;
}
.k-autocomplete.k-state-hover,
.k-dropdown-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-picker-wrap.k-state-hover {
  background-color: #AD172B;

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
  background-position: 50% 50%;
  border-color: #dbdbdb;
}
.k-autocomplete.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-picker-wrap.k-state-focused {
  background-color: #AD172B;

  background-image: none, linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
  background-position: 50% 50%;
  border-color: #dbdbdb;
  box-shadow: none;
}
.k-panelbar .k-state-focused.k-state-hover {
  background: #AD172B;
  box-shadow: none;
}
.k-tabstrip-items .k-state-hover {
  border-color: #AD172B;
}
.k-grid td.k-state-selected:hover,
.k-grid tr:hover {
  color: #fff;
  background-color: #AD172B;
}
.k-chart .k-selection:hover {
  border-color: #AD172B;
  box-shadow: inset 0 0 20px rgba(142, 188, 0, 0.5);
}
.k-chart .k-handle:hover {
  background-color: #AD172B;
  width: 3px;
  border-radius: 0;
}
.k-chart .k-navigator-hint .k-tooltip {
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  background: #AD172B;
  color: #fff;
  border-radius: 0;
}
.k-spreadsheet-filter:hover {
  color: #fff;
  background: #AD172B;
  border-color: #6f9300;
}
.k-spreadsheet-popup .k-button:hover {
  background-color: #AD172B;
}
.k-series-a {
  border-color: #AD172B;
  background-color: rgba(142, 188, 0, 0.15);
}
.k-listbox .k-item:hover:not(.k-state-disabled) {
  color: #fff;
  background-color: #AD172B;
  border-color: transparent;
}
.k-dropdowngrid-popup .k-item.k-state-hover {
  border-color: #dbdbdb;
  color: #fff;
  background-color: #AD172B;
}

.k-menu .k-menu-scroll-button:hover, .k-menu .k-state-hover>.k-link {
  color: #fff;
  background-color: #AD172B;
  border-color: #AD172B;
  background-image: none,linear-gradient(to bottom, #AD172B 0, #AD172B 100%);
}
.k-menu .k-state-selected>.k-link {
  background-color: #AD172B;
}
.k-draghandle.k-state-selected:hover, .k-ghost-splitbar-horizontal, .k-ghost-splitbar-vertical, .k-list>.k-state-highlight, .k-list>.k-state-selected, .k-marquee-color, .k-panel>.k-state-selected, .k-scheduler .k-today.k-state-selected, .k-state-selected, .k-state-selected:link, .k-state-selected:visited {
  color: #fff;
  background-color: #AD172B;
  border-color: #AD172B;
}

#reportViewer1 {
  left:0px !important;
  right:0px !important;
}
