@import 'assets/css/font-awesome-pro/css/fontawesome';
@import 'assets/css/font-awesome-pro/css/regular';
@import 'assets/css/font-awesome-pro/css/solid';
@import 'assets/css/font-awesome-pro/css/brands';
@import 'assets/css/font-awesome-pro/css/v5-font-face';
@import 'assets/css/reports.scss';


/* You can add global styles to this file, and also import other style files */
html, body {
  background-color: #f5f5f5;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  height: 100%;

}

body {
  overflow-x: hidden;
  //height: 100vh;
  //display: flex;
  //flex-direction: column;
}

.header-color {
  background-color: #AD172B;
}

.main-logo {
  background-image: url('./assets/images/logo_main_white-new.png');
  width: 162px;
  height: 53px;
  padding: 10px;
  background-size: 142px 30px;
  background-repeat: no-repeat;
  background-position: center;
}

.search-container {
  .p-inputgroup .p-inputwrapper > .p-component {
    width: auto;
  }

  .p-inputtext:enabled:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
  }
}

.img-width100 {
  width: 100%;
  height: auto;
}

ng-component, app-billing-type, app-settings, app-dashboard,
app-roster, app-civic, app-courses, app-programs, app-facets, app-classes,
app-student-roster, app-events, app-event-instances, app-event-locations,
app-training-centers, app-email-templates, app-survey-builder,
app-personnel-files, app-user-surveys, app-user-documents,
app-user-profile, app-user-google-drive, app-user-account,
app-account-adjustments, app-users, app-vitals, app-alerts,
app-prospects, app-unlinked-users, app-campaigns, app-roster-queries,
app-mail-merge-batches, app-mail-merge-templates {
  width: 100%;
}

//
//app-header-nav, ng-sidebar-container {
//  display:flex;
//  flex-direction: row;
//}

.p-component {
  font-family: "Roboto", sans-serif;
}

app-time-entry-form {
  width: 100%;
}

h2 {
  margin-top: 5px;
}

a {
  color: #1E88E3;
}

.height-30 {
  height: 30px;
}

.dark-divider {
  .p-divider.p-divider-horizontal {
    padding: 0px;
  }

  .p-divider-content {
    background-color: #666;
    height: 1px;
    width: 100%;
  }

  .p-divider-solid.p-divider-horizontal:before {
    border-top: 0;
  }
}


.float-right {
  float: right;
}

.float-left {
  float: left;
}

.auto-width {
  width: auto;
}

.flip-icon {
  rotation: 180deg;
}

.hide {
  display: none;
}

.clickable {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.move {
  cursor: move;
}

.menu-container {
  // set max-wdith and margin to present site correctly on extra wide displays
  max-width: 1920px;
  margin: 0 auto;
}

.page-container {
  padding: 10px 15px 30px 15px;

  // set max-wdith and margin to present site correctly on extra wide displays
  max-width: 1920px;
  margin: 0 auto;

  .p-card-content {
    padding: 0;
  }
}

.add-card {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 1000;

  .p-card {
    background-color: #fff !important;
    padding: 15px;
  }

  .card-header {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    display: inline-block;
  }

  .card-header-close-btn {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
  }

}

.form-card {
  position: absolute;
  right: 120px;
  top: 40px;
  z-index: 1000;
  max-width: 250px;

  .p-card {
    background-color: #fff !important;
    padding: 15px 5px;
  }
}

.options-card {
  position: absolute;
  right: 120px;
  top: 40px;
  z-index: 1000;
  max-width: 250px;

  .p-card {
    background-color: #fff !important;
    padding: 15px 5px;
  }
}

.widget-card {
  position: absolute;
  right: 0px;
  top: 20px;
  z-index: 1000;
  width: 150px;
  height: 70px;


  .p-card {
    background-color: #eee !important;
    padding: 3px 5px;
    min-height: auto !important;
  }
}


.select-button {
  //width: min-content;
  //flex-basis: auto;
}

.no-padding-container {
  .page-container {
    padding: 0;
  }
}

.sub-header {
  font-size: 14px;
  margin: 10px 0;
}

a:focus, li:focus {
  outline: none;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}

.p-panel-header h4 {
  margin-left: 10px;
  font-weight: normal;
}

.full-width {
  width: 100%;
}

.ut-apidomain {
  z-index: 16000;
  position: fixed;
  left: 50%;
  top: 0;
  width: 40em;
  margin-top: 1em;
  margin-left: -20em;
  border: 1px solid #000000;
  border-radius: 10px;
  text-align: center;
  color: #111;
  background-color: #fff2e2;
}


.p-card, .p-button {
  border-radius: 0;
}

.p-button-auto-width {
  .p-button {
    width: auto;
  }
}

.no-margin-button {
  .p-button {
    margin: 0px;
  }
}

.active-sub-tab {
  background: #669acd !important;
}

.list-button-label {
  width: auto !important;
  height: 24px;
}

.truncate-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.text-align-right {
  text-align: right;
}

.justify-right {
  justify-content: right;
}

.text-align-left {
  text-align: left;
}

.text-align-middle {
  text-align: center;
}

.content-container {
  float: left;
  width: 100%;
  height: 100%;
}

.align-center {
  text-align: center;
}

.checkbox {
  height: 15px;
}

.divider {
  .p-divider.p-divider-horizontal:before {
    border-top: 1px solid #999;
  }
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 13px;
}

.checkbox-options .p-checkbox-label {
  margin-top: 5px;
}

.checkbox-label-fix label {
  margin-top: 6px;
}

.p-checkbox {
  height: 15px;
}

.checkbox-container {
  padding-top: 26px;

  label {
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 0 2px;
    display: inline;
  }
}

.checkbox-container-tight {
  padding-top: 5px;

  label {
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 0 2px;
    display: inline;
  }
}

.public-card {
  .p-card {
    padding: 10px 25px;
  }

  .public-logo {
    max-width: 300px;
    width: 100%;
  }
}

.options-radio-input {
  margin: 3px 5px;
}

.p-multiSelect {
  background-color: #fff;;
}

.p-dropdown .p-icon-wrapper {
  pointer-events: all;
}

.p-dropdown-clear-icon {
  margin-right: 3px;
}


.form-input {
  .p-inputtext {
    margin-top: 3px !important;
  }
}

.pii-custom-input {
  .p-inputgroup {
    display: flex;
  }

  p-inputmask {
    width: 100%;
  }

  input {
    width: 100%;
    height: 30px;
  }
}

.p-inputnumber-button-group {
  .p-button.p-button-icon-only {
    padding: 0px;
  }
}


form {
  .p-inputtext {
    width: 100%;
    //border-top: 0;
    //border-left: 0;
    //border-right: 0;
    //border-radius: 0;
    font-size: 13px;
    padding-top: 7px;
  }

  label {
    font-size: 12px;
    margin-bottom: 5px;
    display: flex;
  }


  .input-container {
    margin: 5px 0;


    .p-radiobutton-label {
      padding-top: 5px;
    }
  }

  .form-checkbox {
    padding-top: 24px;

    label {
      font-size: 12px;
      font-weight: bold;
      margin-top: 4px;
    }
  }

  .tab-form-buttons {
    button {
      margin-left: 5px;
    }
  }

  .radio-container {
    margin-top: 3px;

    .radio-label {
      width: auto;
      float: left;
      margin: 3px;
    }

    .radio-input {
      float: left;
    }

    p-radiobutton {
      float: left;
    }
  }

  .mask-container {
    .no-border {
      border: 0;
      background-color: transparent;
      padding: 0
    }
  }
}

.custom-add-padding-button {
  padding: 7px 10px;
}


.add-display-flex {
  display: flex;
}

.stand-alone-calendar {
  label {
    width: 100%;
    float: left;
  }

  p-calendar {
    display: block;
    float: left;
  }

  .calendar-search {
    margin-left: 3px;
    height: 28px;
  }
}

.checkbox-label {
  float: left;
}

.training-center {
  .checkbox-input label {
    margin-top: 6px;
  }
}


.nonform-checkbox-container {
  p-checkbox {
    float: left;
  }

  margin: 5px 0;
}

p-inputMask {
  background: transparent;
  border: 0;
}


.p-button-icon-only .p-button-label {
  display: none;
}

.project-select {
  .p-multiselect-item {
    width: 80%;
  }

}

.multi-select {
  .p-multiselect-label-container {
    background-color: #fff;
  }


}

.primary-container {
  font-size: 11px;
  position: absolute;
  right: 5px;
}

.primary-select-container {
  position: absolute;
  right: 5px;
  top: 2px;
  font-size: 13px;
}


.info-icon-button-container {
  line-height: 19px;

  span {
    line-height: 18px;
  }
}

.info-icon-button {
  border: 0 !important;
  box-shadow: none !important;
  margin: 0;
  height: auto !important;
  padding: 0 !important;


  .pi-info-circle {
    //margin-top:10px;
  }
}

.inline-button-text {
  display: inline-block;
  margin-bottom: 12px;
}

.upload-file-container {
  border-bottom: 1px solid #e1e1e1;
}

.upload-icon {
  display: inline-block;
  margin: 5px 5px 0px 0px;
  text-align: center;

  span {
    padding-left: 6px;
  }
}


.back-button {
  width: auto !important;
}

.inline-back-button {
  height: 100%;
  width: 20px;
  background-color: #607D8B;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px 0 0 10px;
  text-align: center;
  padding-top: 35px;
  cursor: pointer;
  z-index: 3;

  i {
    color: #fff;
    font-size: 18px;
    top: 50%;
    position: absolute;
    margin-top: -5px;
    left: 2px;
  }
}

.no-padding {
  padding: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.p-button {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

//---------------------------------------sidebar---------------------------//
.ng-sidebar {
  background-color: #F8F9FD;
  border-right: 1px solid rgba(0, 0, 0, .12);
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 2px 4px 8px rgba(0, 0, 0, .28);
  width: 239px;
}

.ng-sidebar-content {
  padding: 0 0 0 0;
  display: flex;
  min-height: 100%;
  min-width: 100%;
}

.ng-sidebar__content {
  //TODO test before removing
  //position:unset !important;
  //height:calc(100% - 94px) !important;
  // overflow:visible !important;

}

.ng-sidebar-container {
  //TODO test before removing
  //height:calc(100% - 86px) !important;
  //height:calc(100% - 120px) !important;
  //overflow:visible !important;
  overflow-y: scroll;
  z-index: 900;
  width: 100%;
}


//---------------------------------------end sidebar---------------------------//

//---------------------------------------- Alerts sidebar --------------------//
.alertSidebar {
  .ng-sidebar {
    background-color: #dddddd;
    max-width: 350px;
  }
}

//--------------------------------------- End alerts sidebar ------------------//

//--------------------------------------- Snapshot ----------------------//

.snapshot {
  .p-tree {
    background-color: transparent;
    border: 0px;
  }

  .p-datatable-wrapper {
    overflow-x: hidden;
  }
}

.print-canvas th {
  padding-bottom: 5px !important;
  text-decoration: none !important;

  .header-text {
    overflow: visible !important;
  }
}

//---------------------------------------toast---------------------------//
.p-toast-top-right {
  position: absolute;
  margin-top: 50px;
  z-index: 1100;
}

.static-toast {
  position: relative;
  width: 100%;

  .p-toast {
    position: relative;
    width: 100%;
    margin: 0px;
  }

  .p-toast-message-icon {
    font-size: 1.5rem !important;
  }

  .p-toast-message-content {
    padding: .5rem !important;
  }

  .p-toast-summary {
    padding-top: 5px;
  }
}

//---------------------------------------end toast---------------------------//


//---------------------------------------buttons---------------------------//

.p-button.p-button-icon-only.p-button-rounded {
  height: 25px;
}

.p-button.p-button-icon-only {
  width: 25px;
}

.p-fluid {
  .add-location {
    width: auto;
  }
}

.p-button.p-button-sm {
  width: auto !important;
}


//---------------------------------------end buttons---------------------------//

//--------------------------------------- list filter -----------------------//
.filter-toggle {
  position: absolute;
  left: -3px;
  top: 71px;

  button {
    border: 0;
    border-radius-topleft: 0;
    border-bottom-left-radius: 0;
  }

  .p-button.p-button-icon-only {
    width: 30px;
  }

  .p-button.p-button-info {
    border: 0;
  }
}

.input-switch-label {
  margin-top: 4px;
  font-weight: bold;
}

.switch-container {
  display: flex;
}

.input-switch-list-label {
  margin-top: 3px;
  margin-right: 3px;
  font-size: 13px;
  font-weight: bold;
}

.switch-container-right {
  display: flex;
  justify-content: right;
}

.list-filter-buttons {
  button {
    display: inline-flex;
    margin-right: 2px;
  }

  .p-button {
    width: auto;
    // margin-left:5px
  }

  p-button:first-child {
    margin-right: 2px;
  }
}

.active-filter-list {
  padding-left: 18px;

  h3 {
    width: auto;
    display: inline-flex;
  }

  .filter-item-container {
    display: inline-flex;
    padding-left: 5px;
  }

  .filter-item {
    display: inline-flex;
    margin-right: 5px;
    padding: 5px 7px;
    border-left: 2px solid #AD172B;
    background-color: #fff;
    border-radius-top-right: 3px;
    border-radius-bottom-right: 3px;
  }
}

.filter-active {
  background-color: #AD172B !important;
  border: 0;
}

.convert-to-member-btn {
  float: left;
  width: auto !important;
  margin-top: 3px !important;
  margin-right: 3px !important;
  height: 25px;
}

.convert-to-member-btn-detail {
  width: auto !important;
  margin-top: 3px !important;
  margin-right: 3px !important;
  height: 25px;
}


//--------------------------------------end list filter -----------------------//

//------------------------------------- billing list ------------------------//
.billing-list {
  h4 {
    font-weight: normal;
  }
}

.add-edit-billing-rates {
  .truncate-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    max-height: 14px;
  }

  .custom-dropdown {
    margin-top: 0px;
  }

}


// ----------------------------- Report Builder ---------------------------//
.report-builder {

  .p-steps ul {
    border-radius: 8px;

  }

  .p-steps-number {
    display: none;
  }

  .p-steps .p-steps-item:before {
    display: none;
  }

  .p-steps .p-steps-item .p-menuitem-link {
    background-color: transparent;;
  }

  .p-steps {
    background-color: #495057;
    border-radius: 8px;

    .p-menuitem-link {
      cursor: pointer;
    }

    a:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    color: #fff;
  }

  .p-steps-item {
    padding: 10px 0;
  }

  .p-steps-item:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .p-steps-item:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .p-highlight {
    background-color: #7d8084;
  }

  .p-highlight a::after {
    position: absolute;
    top: 40px;
    content: "";
    font-size: 0;
    line-height: 0;
    width: 0;
    border-top: 10px solid #7d8084;
    border-left: 10px solid #f5f5f5;
    border-right: 10px solid #f5f5f5;
  }
}

//------------------------------ end report builder ---------------------//


//----------------------------------------------menu----------------------------------//
.p-megamenu-horizontal .p-megamenu-root-list {
  width: 100%;
}

body app-header-nav {
  z-index: 1000;
}

.prompt-background {
  background-color: #ececec;
}

//---------------------------------------------end menu-------------------------------//

//--------------------------------------------table styles---------------------------//
.p-datatable .p-datatable-thead > tr > th {
  position: relative; // do not move or touch!!
  padding: 8px 9px 8px 5px;
  height: 50px;
}

.frozen-column-table .p-datatable-thead > tr > th {
  position: sticky; // do not move or touch!!
}

.p-datatable .header-text {
  z-index: 10;
  overflow: hidden;
}

.p-datatable .p-datatable-thead > tr > th > .header-actions {
  font-size: 9px;
  position: absolute;
  right: 1px;
  top: 1px;
  margin: 0;
  z-index: 9;
  height: 43px;
  width: 17px;

  p-sorticon {
    font-size: 8px;
    padding-top: 3px;
    height: 13px;
    width: 15px;
    position: relative;
    top: 7px;
    display: block;
  }

  p-columnfilter {
    position: relative;
    height: 15px;
    width: 13px;
    top: 16px;
    right: -6px;
    display: block;

    .p-column-filter-menu-button {
      margin: 0 2px 0 0;
      bottom: 3px;
      height: 18px;
      width: 15px;
      position: relative;
    }
  }


}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  width: 11px;
  height: 11px;
}

.p-datatable .p-column-filter-menu {
  width: 13px;
  height: 13px;
}

.p-datatable-tbody .expand-column, .p-datatable-thead .expand-column {
  max-width: 52px !important;
}

.global-filter {
  margin-top: 5px;
}


.table-page-container {
  margin-top: 15px;
  //display:flex;

  .p-datatable-header {
    background: #fff;
  }
}

.edit-buttons {
  font-size: 20px;
  display: inline;
  background: transparent;

  .p-button:hover {
    background: transparent !important;
  }

  i {
    margin-right: 8px;
    color: #607d8b;
    cursor: pointer;
  }
}


//--------------------------------------------end table styles---------------------------//

//-------------------------------- sub tabs ------------------------------//
.p-fluid .sub-tab {
  float: left;
  width: auto;
  margin-right: 3px;
}

.active-tab {
  background-color: #9ca7b0 !important;
}

//------------------------------- end sub tabs --------------------------//

//--------------------------------- Trades Skills ----------------------//
.p-picklist-list {
  max-height: 300px;
  overflow: scroll;
}


//---------------------------------- end trade skills -------------------//

//----------------------------- nm overides program page view ------------------//

.p-tabview {
  border: 1px solid #cacaca;
  border-radius: 8px;
  //overflow: hidden;
}

.p-tabview .p-tabview-panels {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  //overflow: hidden;
  background: #e6e5e5;
}


.p-tabview .p-tabview-nav {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: 0;
  flex-wrap: wrap;
  padding-bottom: 5px;
  background: #e6e5e5;
}


.p-tabview .p-tabview-nav li:first-child .p-tabview-nav-link {
  border-left: 0;
  border-top: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #607d8b;
  background: #e6e5e5;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: #607d8b;
  color: #fff;;
  font-weight: 600;
  border-radius: 0;
  border: 1px solid #e6e5ea;
  white-space: nowrap;
  margin-bottom: 2px;
}

.summary-tabs {
  .p-tabview .p-tabview-panels {
    background-color: #fff;
  }
}


.p-dialog .p-dialog-content {
  background: #efefef;
  color: #495057;
  padding: 0 1.5rem 0px 1.5rem;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #efefef;
  color: #495057;
  padding: 1.5rem;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 3rem;
  height: 3rem;
  color: #6c757d;
  border: 0 none;
  background: #fff
}


.tox-tinymce-aux {
  z-index: 20000 !important;
}

// -------------------------   GRID VIEW  ----------------------------------- //

.p-datatable .p-datatable-thead > tr > th {
  background: #6c757d;
  color: #fff;
  border-right: rgba(255, 255, 255, .2) 1px solid;
  //display:flex;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: #dddddd;
  color: #666;
}

.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-word;
}

.p-frozen-column {
  // left:0px !important;
}

.grid-filter-buttons {
  margin-top: 8px;
  display: inline-flex;

  button {
    margin-right: 3px;
    padding: 0 5px;
  }

  .p-button.p-button-icon-only {
    padding: .5rem 10px;
  }
}

.global-filter {
  .p-inputtext {
    width: auto;
    min-width: 300px;
    margin-right: 5px;
  }
}

.remove-flex {
  th {
    flex: none !important;
  }

  td {
    flex: none !important;
  }
}

// ------------------------ Wall --------------------------- //
.card-image {
  width: 100%;
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  background-size: cover;
}

.p-virtualscroller .p-virtualscroller-content {
  background: transparent;
}

.wall-card-container {
  padding-bottom: 10px;

  .p-card-header {
    text-align: center;
  }

  .p-card {
    height: 100%;
    overflow: hidden;
    margin: 0 10px;
  }
}

.promoted {
  float: left;
  margin: 3px;
  font-size: 15px;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 3px;
  padding: 4px;
}

.cropper-crop-box {
  background-color: white;
}

//---------------- unlayer -----------------//

#editor-2 iframe {
  min-width: 100% !important; //warning will not work well below 1024px
}


.text-editor {
  width: 100%;
  background-color: #fff;
}

.ql-editor {
  background-color: #fff;
}

//---------------------------------------- List Card styles -----------------------------//
.parent-p-card .p-card .list-card {
  background-color: #F6F4F4;

}

.state-cards {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 24%;
  margin: 15px .5%;
  flex: 1 0 auto;


  .p-card {
    height: 100%;
    background-color: #fff !important;
  }
}


.list-filter-container {
  min-height: 500px;
}

.address-card {
  .p-card {
    background-color: #f5f5f5 !important;
  }

}

.business-template-card {
  .p-card {
    background-color: #F6F4F4 !important;
    padding: 15px 10px;
    margin: 5px;
  }
}

.alpha-buttons {
  float: left;

  .p-button {
    width: auto;
  }
}

.list-card {
  margin: 10px 0;
  background-color: #F6F6F6;
  box-shadow: none;
  border: 1px solid #979797;
  border-radius: 4px;

  .p-card-content {
    margin: 0;
    padding: 0;
  }

  .list-header {
    font-size: 18px;
    font-weight: bold;
  }

  .list-subheader {
    margin-top: 1em;
  }

  .left-column {
    cursor: pointer;
  }

  .right-column {

  }
}

.parent-p-card .p-card {
  .address-card {
    padding: 5px;

    .list-header {
      font-size: 16px;
    }

    .list-subheader {
      margin-top: 5px;
    }
  }
}

.add-profile-card {
  .p-card {
    background-color: #F6F4F4 !important;
  }
}

.context-menu {
  position: absolute;
  width: 40px;
  background-color: #607D8B;
  height: 100%;
  top: 0px;
  left: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  .context-menu-item {
    color: #fff;
    font-size: 25px;
    margin: 15px auto;
    text-align: center;
    cursor: pointer;
  }

  .active-type-button {
    opacity: .3;
  }

  .context-menu-item:hover {
    opacity: .5;
  }
}

.profile-tabs {
  .p-tabview {
    padding-left: 37px;
  }

}


.address-container {
  height: 340px;
  overflow: visible;
}

.list-action-buttons {
  float: right;
  margin-top: 10px;
  opacity: .1;
}

.report-action-buttons {
  float: right;
  margin-top: 10px;

  .icon {
    float: left;
    margin: 3px;
    font-size: 15px;
    background-color: #607D8B;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 3px;
    padding: 4px;
    cursor: pointer;
    color: #fff;
  }
}


.list-card:hover {
  .list-action-buttons {
    opacity: 1;
  }
}

.add-edit-header {
  .list-action-buttons {
    opacity: 1;
  }
}

.center-profile {
  display: flex;
  justify-content: center;
}

.tab-container-profile {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.list-container {
  margin-top: 12px;
  padding-bottom: 30px;

  .avatar {
    float: left;
    margin-right: 5px;
  }

  .profile-container {
    display: flex;
    justify-content: center;
  }

  .action-buttons {
    float: right;
  }

  .icon {
    float: left;
    margin: 3px;
    font-size: 15px;
    background-color: #607D8B;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 3px;
    padding: 4px;
    cursor: pointer;

    i {
      color: #fff;
    }

    &:hover {
      background-color: #1E88E3;
    }
  }

  .list-switch {
    float: left;
    padding-top: 2px
  }
}

.list-spinner {
  position: relative;
  top: 10px;
  left: 50%;
  font-size: 30px;
  color: #607d8b;
}

.results-total {
  padding-left: 25px;
  padding-top: 5px;
}

.list-paginator {
  margin-top: 5px;
}

.list-container-header {
  .action-buttons {
    text-align: right;
    padding-right: 25px;
  }

  .add-entry {
    text-align: left;
  }

  .list-paginator-mobile {
    display: none;
  }

  button {
    margin: 8px 0 0 3px;
  }

  p-inputNumber {
    button {
      margin: 0;
    }
  }

  .p-button-info {
    border: 0;
    //background-color: #1788bf;
  }
}

.remove-list-container-header-margin {
  button {
    margin: 0px;
  }
}

.p-tabview-panel {
  //button {
  //  margin: 0;
  //}

  .action-buttons {
    button {
      margin-left: 3px;
    }
  }
}

.list-container-filter-header {
  .action-buttons {
    text-align: right;
  }

  label {
    font-size: 12px;
  }

  button {
    // margin:0px 0 5px 3px;
  }

  .p-button-info {
    border: 0;
    // background-color: #1788bf;
  }
}

.list-container-header {
  form {
    button {
      margin: 0;
    }
  }
}

.org-list {
  .list-subheader {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
  }
}

.post-container {
  width: 98%;
  margin: 0 1% 0 1%;
}

.new-dashboard-post {
  height: 85px;
  width: 100%;
  overflow: hidden;

  p {
    margin-top: 2px;

  }

}

.banner-card {
  width: 98%;
  margin: 0 1% 0 1%;
  overflow: hidden;

  .p-card-body {
    border-radius: 10px;

    .p-card-content {
      border-radius: 10px;
      margin: 0px !important;
      padding: 0px !important;
      overflow: hidden;
    }
  }

  .hero-image {
    overflow: hidden;
    margin: 0px !important;
    padding: 0px !important;
    height: 75px !important;

    h1 {
      line-height: normal !important;
      font-size: 30px !important;
      margin: 10px 0px 0px 0px !important;
    }
  }

  .p-card .p-card-body {
    padding: 0px;
  }
}


//------------------------------------- maps --------------------------------//
agm-map {
  height: 100%;
}

//------------------------------------- end maps --------------------------------//

//----------------------------------- widget stuff ------------------------//

.card-row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  flex-wrap: nowrap;
}

.width1 {
  flex: 0 100%;
  padding: 5px 10px;
}

.width2 {
  flex: 0 50%;
  padding: 5px 10px;
}

.width3 {
  flex: 0 33.333%;
  width: 33.333%;
  padding: 5px 10px;
}

.width4 {
  flex: 0 25%;
  padding: 5px 10px;
}

.col-3 {
  width: 33.333%;
  display: inline-block;
}

.col-4 {
  width: 25%;
  display: inline-block;
}

.p-datatable.p-datatable-striped-widget .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f2f7fb;
}

.base-widget {
  display: flex;

  .p-card {
    position: relative;
    min-height: 200px;
    margin-bottom: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.large-button {
  width: 70%;
  margin: 10px auto;


  .p-button {
    text-align: center;
    justify-content: center;
    height: 50px;
    border-radius: 10px;
    font-size: 22px;

    i {
      font-size: 22px;
    }
  }

}

.medium-button {
  width: 70%;
  margin: 10px auto;


  .p-button {
    text-align: center;
    justify-content: center;
    height: 30px;
    border-radius: 6px;
    font-size: 18px;

    i {
      font-size: 18px;
    }
  }
}

.p-datatable-widget {
  i {
    font-size: 18px;
  }

  .p-datatable-thead > tr > th {
    height: auto !important;
    color: #000000 !important;
    background-color: #FFFFFF !important;
    text-decoration: underline;
    border: 0;
  }

  .p-datatable-tbody > tr > td {
    padding: 8px 3px;
    border: 0;
  }

  .no-wrap {
    text-wrap: nowrap;
  }

  .font-size-10 {
    font-size: 11px;
  }

  a {
    color: #607d8b;
  }
}


.post-body {
  font-size: 15px;
  line-height: 24px;

  p {
    margin-top: 0px;

  }
}


.bottom-btn {
  bottom: 5px;
  margin: 10px auto;
  width: 100%;
}

.graph-min-height {
  min-height: 350px;
}

//---------------------------------- end widget stuff ---------------------//

//--------------------------------member verification ----------------------------//
.member-verification-container {
  .p-divider.p-divider-horizontal {
    margin: 1rem 0;
    padding: 0px;
  }
}

@media screen and (max-width: 540px) {
  .member-verification-container {
    .logo {
      width: 35% !important;
    }

    .member-card {
      width: 95% !important;
    }

    .left-column {
      width: 60% !important;

    }

    .right-column {
      width: 40% !important;
    }

    .member-name {
      font-size: 28px !important;
      top: 32px !important;;
    }
  }
}

//----------------------------------------- Dialog styles ---------------------------//


.dialog-form {
  .parent-form {
    min-height: 300px;
  }

  .p-dialog-content button {
    margin-left: 0;
  }

  .p-checkbox-label {
    margin-left: 0.5rem;
    padding-top: 3px;
  }
}

.p-dialog .p-dialog-content {
  //overflow-y: visible;
}

.p-dialog-content {
  height: 100%;
  overflow-y: scroll;
}

.range-selector {
  button {
    margin: 0;
  }
}

.dialog-table {
  .header-text {
    width: auto !important;
  }
}

.parent-p-card {
  .p-card {
    background: #dddddd;
  }

  .p-card-content {
    padding-top: 10px;
  }
}

.p-card {
  border-radius: 12px;
}

.light-card {
  .p-card {
    background: #eee;
  }
}

.p-paginator {
  background: none;
  color: #000;
  padding: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #fefefe;
  border-color: #fefefe;
  color: #2a2d30;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #2a2d30;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 3px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border: none;
  color: #495057;
  font-weight: 600;
  border-radius: 6px;
  transition: box-shadow 0.2s;
}

.activities-accordion {
  position: relative;
}

.p-accordion-content {
  //border-radius: 0 3px 3px 0;
  border: 0;
}

.p-accordion .p-accordion-header:hover {
  background: none;
}

p-confirmdialog .p-dialog .p-dialog-content {
  border-radius: 0;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
  transition-duration: 0.2s;
}


.p-dialog .p-dialog-footer {
  background: #efefef;
  color: #495057;
  padding: 10px 1.5rem 2rem 1.5rem;
  border-radius: 0 0 12px 12px;
  padding: 0 1.5rem 2rem 1.5rem;
  border-radius: 0 0 12px 12px;

  .p-button {
    margin-top: 5px;
  }

}

//---------------------------------------- End List Card styles -----------------------------//

//---------------------------------------- Grid Card styles -----------------------------//
.grid-container-buttons {
  button {
    margin: 0 0 0 3px;
  }

  .p-button-info {
    border: 0;
    // background-color: #1788bf;
  }
}

.grid-container-buttons-right {
  .action-buttons {
    text-align: right;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  button {
    margin: 0 0 0 3px;
  }

  .p-button-info {
    border: 0;
    //  background-color: #1788bf;
  }


}

.grid-dist-card {
  .p-card {
    background-color: transparent;
    box-shadow: none;
  }
}

//---------------------------------------- End List Card styles -----------------------------//

//---------------------------------------- Status Circles -----------------------------//
.status-circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #999;
}

.circle-green {
  background-color: #339900;
  border: 2px solid #fff;
  box-shadow: 0 0 2px #333;
}

.circle-red {
  background-color: #990000;
  border: 2px solid #fff;
  box-shadow: 0 0 2px #333;
}

//----------------------------------------- End Status Circles ------------------------//

//----------------------------------------- Credit Card Icon stuff ----------------------//
.cc-list {
  display: flex;

  img {
    width: 36px;
  }
}

//----------------------------------------- End Credit Card Icon stuff ----------------------//

//---------------------------------------------- Component paging ------------------------//
.component-paging {
  display: block !important;
  float: left;
  background-color: transparent;
  padding: 0;
}

//---------------------------------------------- Component paging ------------------------//

//--------------------------------------Global Exports -----------------------------//
.export-menu {
  position: relative;
  overflow: visible;
}

.export-options {
  position: absolute;
  padding: 5px;
  min-width: 75px;
  min-height: 50px;
  background: #fff;
  border: #eee 1px solid;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  top: 30px;
  color: #1c1c1d;
  z-index: 10000;
  border-radius: 4px;

  .option {
    position: relative;
    float: left;
    width: 100%;
    padding: 3px 0;
  }
}

//-----------------------------------End Global Exports -----------------------------//

//-----------------------------------------------Spinner---------------------------------//

/* Spinner */
.spinner {
  font-size: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10
}

.fa-spinner {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

//----------------------------------------End Spinner---------------------------//


//-----------------------------------------global form styles----------------------------//

label {
  font-weight: bold;
  font-size: 13px;
  width: 100%;
}

input select {
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.req {
  color: #b3000e;
  //font-size:7px;
}

.red {
  color: #b3000e;
}

.org-dropdown {
  margin-top: 5px;
  width: 100%;
}

body .p-dropdown-panel {
  // z-index:11000 !important;

}

body .facet-category .p-dropdown-items-wrapper {
  max-width: 300px;
}

.select-dropdown {
  .p-dropdown {
    width: 100%;
    //margin:5px 0;
  }
}

.custom-dropdown {
  display: flex;
  margin-top: 4px;
}

.highlight-option {
  font-weight: bold;
  color: #0d89ec !important;
}

.p-multiselect {
  //margin:6px 0;
  font-size: 13px;
}

.description-label {
  width: 80%;
  display: inline-flex;
}

.icon-button {
  width: 20%;
  display: inline-flex;
  justify-content: right;
  position: relative;
  height: 13px;

  i {
    position: absolute;
    font-size: 16px;

  }
}

.tool-tip-line {
  margin: 5px 0px;
}

.filter-tool-tip {
  .p-tooltip-arrow {
    display: none !important;
  }
}

.custom-tooltip {
  width: 300px;
}

//
//.filter-popup {
//  position:absolute;
//  width:150px;
//  height:200px;
//  z-index: 1000000;
//  right:-150px;
//  top:0px
//}
//
//.dist-list-select {
//  .p-dropdown-items-wrapper {
//    overflow-y:auto;
//    overflow-x: visible;
//  }
//
//  .p-dropdown-item {
//    overflow-y:auto;
//    overflow-x: visible;
//  }
//}

.field-container {
  float: right;
  text-align: left;
}

.checkbox {
  height: 15px;

}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 13px;
}

.p-checkbox {
  height: 15px;
}

.login-card {
  .p-card {
    padding: 10px 25px;
  }
}

form {
  .p-inputtext {
    width: 100%;
    font-size: 13px;
    -webkit-appearance: none;
  }

  label {
    font-size: 12px;
  }

  .checkbox-input {
    label {
      width: auto;
      display: unset;
    }
  }
}

.p-dialog-content {
  .p-calendar {
    .p-button {
      margin: 0px;
    }
  }
}


.sms-nonallowedchars {
  background-color: #ffe7e6;
  border-radius: 8px;
  margin: 5px 5px 5px 5px;
  padding: 5px 5px 10px 5px;
}

.red-icon {
  color: #990000;
}

.sms-highimpactchars {
  background-color: #fff2e2;
  border-radius: 8px;
  margin: 5px 5px 5px 5px;
  padding: 5px 5px 10px 5px;
}

.sms-best-practice {
  position: relative;

  ul {
    margin: 0px;
  }

  li {
    line-height: 19px;
  }

  h3 {
    margin-top: 2px;
  }

  .accordion-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  i {
    font-size: 16px;
  }
}

.yellow-icon {
  color: #e8d01c;
}

.sms-stats {
  background-color: #F6F4F4;
  text-align: center;
  border-radius: 8px;
  margin: 5px 5px 5px 5px;
}

.cost {
  color: #339900;
}

.recipient-summary {
  border-radius: 8px;
  margin: 5px 5px 5px 5px;
}

.recipient-stats {
  //text-align: center;
  border-radius: 8px;
  margin: 5px 5px 5px 5px;
}

.p-button {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.inline-button {
  width: auto !important;
  padding: 10px 10px;
  margin-left: 5px;
}

.list-tab-button {
  width: auto !important;
}

.list-container-header {
  .inline-button {
    width: auto !important;
    padding: 10px 10px;
    margin-left: 5px;
    margin-top: 10px;
  }

  .inline-button-single-row {
    width: auto !important;
    padding: 7px 10px;
    margin-left: 5px;
    margin-top: 20px;
  }
}

.tox-tinymce--disabled {
  opacity: .6;
}

.save-spinner {
  margin-right: 5px;
}

.dialog-save-spinner {
  font-size: 26px;
}

.tab-form-buttons {
  margin-top: 10px;
  text-align: right;
}

.tab-form-buttons-left {
  margin-top: 10px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #333;
  background: #ffffff;
  width: 15px;
  height: 15px;
  color: #495057;
  border-radius: 0;
}

.error {
  color: #b3000e;
}

.warning-red {
  color: #b3300e !important;

  i {
    color: #b3300e !important;
  }
}

.negative {
  color: #990000 !important;
}

.positive {
  color: #339900;
}

.blue {
  color: #0033cc;
}

.filter-item-padding {
  padding: 0 3px 0 0 !important;
}

.or-background {
  background-image: url('./assets/images/or-background.png');
  height: 15px;
  width: 96%;
  background-repeat: repeat-x;
  margin: 0 2%;
  text-align: center;

  .or-text {
    background-color: #e6e5e5;
    width: 20px;
    display: inline-flex;
    text-align: center;
  }
}

.p-datepicker {
  min-width: auto !important;
}

//-----------------------------------------end global form styles----------------------------//

//-----------------------------------------Dashboard styles---------------------------//
.dashboard-card {
  flex-direction: column;
  height: 100%;

  .p-card-body {
    padding: 0;
  }

  .p-card-body {
    padding: 10px;
    font-size: 13px;
    word-break: break-word;
    width: 100%;
  }
}


@media (max-width: 767px) {
  .dashboard-card {
    display: flex;
    margin: 15px 10px;
    flex-direction: unset;
    height: auto;

    .card-image {
      height: 200px;
    }
  }


}

//-----------------------------------------End Dashboard styles---------------------------//

//----------------------------------------- upload stuff -------------------------------//
.upload-buttons {
  .p-fileupload {
    display: inline;

  }

  .p-fileupload-choose {
    padding: 10px 15px;
  }
}


//-----------------------------------------footer styles----------------------------//

#footer {
  background: #ccc;
  /*font-size: 0.9rem;*/
  z-index: 900;
  line-height: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 25px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  a {
    color: #444;
    text-decoration: none;
  }
}

footer {
  z-index: 50000;
}

//-----------------------------------------end footer styles----------------------------//

//---------------------------------------primeng accordion---------------------------//
.p-accordion {
  padding-bottom: 10px;
}

.side-bar {
  .p-accordion .p-accordion-content {
    border: 0;
  }

  .p-accordion .p-accordion-header-link {
    background: transparent;
  }

  .p-highlight .p-accordion-header-link {
    background: transparent !important;
  }

  .p-sidebar-left {
    top: auto;
  }
}

.p-accordion .p-accordion-content {
  background: #f8f9fa;
}

.detail-accordion {
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: #607d8b;
    color: #fff;

  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    background-color: #9ca7b0;
    color: #fff;
    border-bottom: 1px solid #eee;
  }
}


//---------------------------------------end primeng accordion---------------------------//


//---------------------------------------time entry---------------------------//
.time-entry-container {
  padding-top: 15px;

  .p-fluid .p-button {
    width: auto;
  }

  h2 {
    margin-bottom: 5px;
  }

  .add-entry {
    .p-button.p-button-icon-only {
      padding: 7px 10px;
      margin-top: 25px;
    }
  }
}

.time-accordion {
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: transparent !important;

  }

  .p-accordion-header-text {
    font-size: 17px;
  }

  .p-accordion-content {
    background-color: transparent;
  }
}

.facet-container {
  background-color: #fff !important;
}

.sub-facet-container {
  background-color: #F6F4F4 !important;
}


.time-entry-button {
  margin-right: 3px;
  width: auto;
}

.text-align-right-desktop {
  text-align: right;
}

//---------------------------------------end time entry---------------------------//


//-------------------------------- wage rate ------------------------//
.review-wage-rates {
  width: auto !important;

  span {
    font-size: 11px;
  }
}

//----------------------------- end wage rate -----------------//

//--------------------------------------- percent container ---------------------//
.percent-container {
  .p-progressbar .p-progressbar-value {
    background-color: #6EBA62;
  }

  .p-progressbar {
    height: 2.0rem;
  }

  .p-progressbar-determinate .p-progressbar-label {
    top: 3px;
    color: #fff;
  }
}


//--------------------------------------end percent container ----------------------//

//---------------------------------------recaptcha---------------------------//
//https://brizzo.net/tips/hide-recaptcha-v3-badge/
.grecaptcha-badge {
  opacity: 0;
  z-index: 0;
}

//---------------------------------------end recaptcha---------------------------//

//mobile
@supports (-webkit-touch-callout: none) {
  body {
    //margin-top:50px;
  }
}

@media screen and (max-width: 1028px) {
  .card-row {
    flex-direction: column;
  }
}

@media screen and (min-width: 1028px) and (max-width: 1479px) {
  .p-tabview-panel {
    .list-filter-buttons {
      .p-button {
        width: 100%;
        margin-bottom: 5px;
      }

      .p-button:first-child {
        margin-right: 2px;
      }
    }
  }
}

@media screen and (min-width: 1020px) and (max-width: 1538px) {
  .list-filter-buttons {
    .p-button {
      width: 100%;
      margin-bottom: 5px;
    }

    .p-button:first-child {
      margin-right: 2px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .list-container-header {
    margin-top: 20px;
  }

  body .facet-category .p-dropdown-items-wrapper {
    max-width: none;
  }

  .list-container {
    max-height: none !important;
    overflow-y: visible !important;
    height: auto !important;
  }

  .list-filter-container {
    min-height: auto;
  }

  .parent-p-card .p-card-content {
    overflow: visible;
  }

  .list-container-header {
    height: auto !important;
  }

  .ng-scroll-viewport {
    position: inherit !important;
    contain: none !important;
    overflow-y: visible !important;
  }

  .ng-scroll-viewport-wrapper {
    overflow-y: visible !important;
    position: inherit !important;
  }

  .ng-scrollbar-wrapper {
    position: inherit !important;
  }

  .ng-sidebar__content {
    overflow-y: scroll !important;
  }


}

.ng-scroll-content {
  display: block !important;
}

@media screen and (max-width: 767px) {
  .list-paginator {
    display: none;
  }
  .right-dropdown {
    margin-right: 0 !important;
  }

  .list-container-header .list-paginator-mobile {
    display: block;
  }

  .list-action-buttons {
    float: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .add-edit-header h2 {
    text-align: center;
    margin-top: 3px;
  }

  .add-edit-header h4 {
    text-align: center;
    padding: 0px 4px;
  }

  .address-container {
    height: auto;
  }

  .list-header {
    text-align: center;
    margin-top: 3px;
  }

  .list-card .list-subheader {
    text-align: center;
  }

  .p-tabview .p-tabview-panels {
    padding: 3px;
  }

  .p-card .p-card-body {
    padding: 6px 4px;
  }

  .mobile-button-full-width {
    .p-button.p-button-icon-only {
      width: 100% !important;
      margin-bottom: 8px;
    }
  }

  .sub-facet-container {
    .p-card-body {
      padding: 6px 10px !important;
    }
  }

  .text-align-right-desktop {
    text-align: left;
  }

  .add-entry {
    text-align: right;
  }


  .list-container-header .action-buttons {
    text-align: center;
    margin-bottom: 10px;
    padding-right: 0;
  }

  .list-container .icon {
    float: left;
    margin: 3px;
    font-size: 15px;
    background-color: #607D8B;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 3px;
    padding: 9px;
    cursor: pointer;
  }

  .list-filter-buttons {
    text-align: center;
  }

  .results-total {
    text-align: center;
  }

  .p-multiselect {
    font-size: 16px;
  }

  .login-card {
    margin-top: 50px;
    background-color: transparent;
    box-shadow: none;
  }

  .mobile-center {
    text-align: center;
  }

  .hide-on-mobile {
    display: none;
  }

  .p-dialog {
    max-height: 79% !important;
  }


  form .p-inputtext {
    font-size: 16px;
  }

  input[type='text'],
  input[type='number'],
  textarea {
    font-size: 16px;
  }

  .hero-image {
    display: none;
  }

  h2 {
    font-size: 1.3em;
  }

  .dataperspective-content {
    max-width: 200px;
    min-width: auto !important;
  }

  .p-tabview-nav {
    flex-wrap: wrap;
  }

  .p-tabview .p-tabview-nav {
    border: 0;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    //border: 0;
  }
}

.form-side-by-side-button {
  height: 25px;
  width: 25px !important;
}

.add-edit-header {
  background: #d6d6d6;
  color: #495057;
  padding: .7rem;
  margin-bottom: 16px;
  border: 0;
  border-radius: 12px;
  position: relative;

  h4, h3, h2, h1, {
    margin-left: 10px;
  }

  h4 {
    font-weight: 400;
    margin: 5px 0 15px 0;
  }

  h2 {
    margin: 15px 0 5px 0;
    font-size: 25px;
  }
}

.mobile-nav {
  .menubar {
  }
}

.mobile-api {
  bottom: 0;
  top: auto;
}

.mobile-wrapper {
  .hero-image {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1275px) {

  .avatar-with-image, .avatar-without-image {
    width: 70px;
    height: 70px;
    min-width: auto;
    max-width: none;
  }
}


//----------------------------- override keep last ------------------//
.relative {
  position: relative;
}

.force-auto-width {
  .p-button {
    width: auto !important;
  }
}

.margin-top-0 {
  margin-top: 0px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-top-4 {
  padding-top: 4px !important;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.margin-top-17 {
  margin-top: 16px !important;
}

.margin-top-16 {
  margin-top: 17px !important;
}

.margin-top-25 {
  margin-top: 25px !important;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-9 {
  margin-top: 9px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-7 {
  margin-top: 7px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-3 {
  margin-top: 3px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-left-10 {
  margin-left: 10px !important;
}


.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.no-margin {
  margin: 0;
}

.no-margin-button button {
  margin: 0;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-bottom-150 {
  padding-bottom: 150px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.no-border {
  border-bottom: 0;
  margin: 0;
}


.form-changed-dialog {
  .p-dialog {
    box-shadow: 0 3px 3px #eee;
    border-radius: 12px;
  }

  .p-dialog-mask {
    position: fixed;
    top: 80px !important;
    right: 10px !important;
    left: auto;
    width: 300px !important;
    height: auto !important;
    display: block !important;
  }

  .p-dialog-header {
    background-color: #d9a300;
    padding: 5px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    width: 100%;
    display: block;
  }

  .p-dialog-content, .p-dialog-footer {
    background-color: #ffecb3;
  }

  .p-dialog-title {
    font-size: 12px !important;
  }

  .p-dialog-header-icons {
    display: none !important;
  }

  .p-confirm-dialog .p-dialog-content {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .p-dialog-footer {
    padding-bottom: 10px;
  }

}

.custom-mask-style {
  background-color: transparent !important;
}

.p-multiselect .p-multiselect-label {
  padding-top: 6px;
  padding-bottom: 6px;
}

.p-accordion-header-text {
  line-height: 1;
  text-transform: uppercase;
}

.p-button {
  color: #ffffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  background: #607d8b;
  border: 0;
}

.list-card {
  margin: 5px 10px;
  background-color: #f6f4f4;
  box-shadow: none;
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 4px;
  position: relative;
}

//--------------------------------- Avatar list ----------------------//

.avatar-with-image {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  max-height: 75px;
  max-width: 75px;
  align-content: center;

  img {
    width: 100%;
  }
}

.avatar-without-image {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  max-height: 75px;
  max-width: 75px;
  align-content: center;

  img {
    width: 100%;
  }
}

.org-avatar-with-image {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 60px;
  align-content: center;
  //border: 2px solid #fff;
  box-shadow: 0 0 0 1px #000000;
  vertical-align: middle;

  img {

  }
}

.post-avatar-with-image {
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  max-height: 95px;
  max-width: 95px;
  align-content: center;

  img {
    width: 100%;
  }
}

.post-avatar-without-image {
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  max-height: 95px;
  max-width: 95px;
  align-content: center;

  img {
    width: 100%;
  }
}

.active-status-org {
  border: 2px solid #fff;
  box-shadow: 0 0 0 3px #66A9DF;
  background: #fff;
}

.inactive-status-org {
  border: 2px solid #fff;
  box-shadow: 0 0 0 3px #DB5F60;
  background: #fff;
}

.active-status {
  border: 2px solid #fff;
  box-shadow: 0 0 0 3px #66A9DF;
  background: #66A9DF;
}

.inactive-status {
  border: 2px solid #fff;
  box-shadow: 0 0 0 3px #DB5F60;
}

.deceased-status {
  border: 2px solid #fff;
  box-shadow: 0 0 0 3px #9d9d9d;
}

.suspended-status {
  border: 2px solid #fff;
  box-shadow: 0 0 0 3px #ffa646;
}

.deceased-status-no-image {
  background-color: #9d9d9d;
}

.suspended-status-no-image {
  background-color: #ffa646;
}

.active-status-no-image {
  // border: 2px solid #66A9DF;
  background-color: #66A9DF;
}

.inactive-status-no-image {
  // border: 2px solid #DB5F60;
  background-color: #DB5F60;
}

.has-position {
  position: absolute;
  background-color: #66A9DF;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  bottom: 3px;
  right: 6px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
}

.has-position-no-image {
  position: absolute;
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  bottom: 3px;
  right: 6px;
  color: #66A9DF;
  text-align: center;
  padding-top: 5px;
  border: 1px solid #66A9DF;
}

.inactive-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/inactive-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
}

.suspended-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/suspended-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
}

.gray-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/gray-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
}

.green-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/green-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
}

.yellow-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/yellow-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
}

.inactive-flag-hard-corner {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/inactive-flag-hard-corner.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
  margin-left: 20px;
}

.suspended-flag-hard-corner {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/suspended-flag-hard-corner.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
  margin-left: 20px;
}

.gray-flag-hard-corner {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/gray-flag-hard-corner.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
  margin-left: 20px;
}

.green-flag-hard-corner {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/green-flag-hard-corner.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
  margin-left: 20px;
}

.yellow-flag-hard-corner {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/yellow-flag-hard-corner.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  z-index: 5;
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .list-card {
    margin: 10px 10px;
    background-color: #eeeeee;
    box-shadow: none;
    border: none;
    //border-radius: 12px;
    padding: 10px 10px 20px 10px;
    //filter: drop-shadow(4px 4px 4px #ddd);
  }
}

.list-card:hover {
  background-color: #f4f5f6 !important;
}

.list-card .list-header {
  font-size: 20px;
}

.p-hidden-accessible {
  display: none;
}

//---------------------------------------start detail container---------------------------//
.float-right-buttons {
  float: right;
  cursor: pointer;
}

//---------------------------------------end detail container---------------------------//

//---------------------------------------start print---------------------------//
@media print {
  .menubar, .top-nav, .back-button, .fa-print, #footer {
    display: none !important;
  }
}

//---------------------------------------end print---------------------------//

//---------------------------------------start highcharts---------------------------//
highcharts-chart {
  border-radius: 12px;
  width: 100%;
  display: block;
}

#chart {
  display: table-cell;
  position: absolute;
  width: 95%;
  height: 100%;
}

//---------------------------------------end highcharts---------------------------//

.vital {
  text-align: center;
}

.inactive {
  opacity: 0.6;
}

.inactive-lighter {
  background-color: rgba(255, 255, 255, .6);
  width: 90%;
  position: absolute;
  height: 20px;
}

//.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
//  box-shadow: inset 0 -2px 0 0 rgb(173, 23, 43);
//}

.p-card-title {
  i {
    font-size: 1.3rem;
  }
}

//primeng progressBar
.data-progress-bar {
  padding-left: 25px;
  padding-right: 25px;

  .p-progressbar {
    height: 5px;
  }

  .p-progressbar .p-progressbar-value {
    background: #607d8b;
  }
}


//Results loading CSS animation
.loading-total:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.gly-rotate-45 {
  //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

.gly-rotate-22-5 {
  //filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(22.5deg);
  -moz-transform: rotate(22.5deg);
  -ms-transform: rotate(22.5deg);
  -o-transform: rotate(22.5deg);
  transform: rotate(22.5deg);
  display: inline-block;
}

.export-excel {
  background-color: #1D6F42;
}

.p-dialog-content .fa-file-excel {
  color: #1D6F42;
}

.export-pdf {
  background-color: #CB2128;
}

.p-dialog-content .fa-file-pdf {
  color: #CB2128;
}
